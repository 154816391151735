import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography } from '@mui/material';

const AddTaskForm = ({ onTaskAdded }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error.response ? error.response.data : error.message);
      }
    };
    fetchCategories();
  }, []);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validate fields
    if (!title || (!category && !newCategory)) {
      setError('Title and category are required.');
      return;
    }

    let selectedCategory = category;
    let newCategoryData = null;

    // Add new category if provided
    if (newCategory) {
      try {
        const color = getRandomColor();
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/categories`, { name: newCategory, color }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        selectedCategory = response.data.name;
        newCategoryData = response.data;
        setCategories([...categories, newCategoryData]);
        setNewCategory('');
      } catch (error) {
        console.error('Error adding new category:', error.response ? error.response.data : error.message);
        return;
      }
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, {
        title,
        description,
        category: selectedCategory,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const newTask = response.data;
      onTaskAdded(newTask, newCategoryData);
      setTitle('');
      setDescription('');
      setCategory('');
    } catch (error) {
      console.error('Error adding task:', error.response ? error.response.data : error.message);
      setError('Failed to add task.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <Typography color="error">{error}</Typography>}
      <Box mb={2}>
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          required
        />
      </Box>
      <Box mb={2}>
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          multiline
          rows={4}
        />
      </Box>
      <Box mb={2}>
        <TextField
          select
          label="Select a Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          fullWidth
          SelectProps={{
            native: true,
          }}
        >
          <option value="" />
          {categories.map((cat) => (
            <option key={cat._id} value={cat.name}>
              {cat.name}
            </option>
          ))}
        </TextField>
      </Box>
      <Box mb={2}>
        <TextField
          label="Or add a new category"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          fullWidth
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button type="submit" variant="contained" color="primary">
          Add Task
        </Button>
      </Box>
    </form>
  );
};

export default AddTaskForm;
