import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { AppBar, Toolbar, Typography, Button, Box, Container } from '@mui/material';
import Login from './components/Login';
import Register from './components/Register';
import TaskList from './components/TaskList';
import Profile from './components/Profile';
import CompletedTasks from './components/CompletedTasks';
import Categories from './components/Categories';
import RequestReset from './components/RequestReset';
import ResetPassword from './components/ResetPassword';
import LandingPage from './components/LandingPage';
import UserContext, { UserProvider } from './UserContext';

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
          // Token has expired
          localStorage.removeItem('token');
          delete axios.defaults.headers.common['Authorization'];
        } else {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          setUser({ ...decodedToken, token });
        }
      } catch (error) {
        console.log('Invalid token');
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
      }
    }
  }, []);

  const handleLogin = () => {
    console.log('User logged in');
    setUser(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
    console.log('User logged out');
    setUser(null);
  };

  return (
    <UserProvider>
      <Router>
        <div>
          <AppBar position="static">
            <Toolbar>
              <NavigationBar user={user} onLogout={handleLogout} />
            </Toolbar>
          </AppBar>
          <Container>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={!user ? <Login onLogin={handleLogin} /> : <Navigate to="/tasks" />} />
              <Route path="/register" element={!user ? <Register onRegister={handleLogin} /> : <Navigate to="/tasks" />} />
              <Route path="/tasks" element={user ? <TaskList onLogout={handleLogout} /> : <Navigate to="/login" />} />
              <Route path="/completed-tasks" element={user ? <CompletedTasks /> : <Navigate to="/login" />} />
              <Route path="/profile" element={user ? <Profile /> : <Navigate to="/login" />} />
              <Route path="/categories" element={user ? <Categories /> : <Navigate to="/login" />} />
              <Route path="/request-reset" element={<RequestReset />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route path="*" element={<Navigate to={user ? "/tasks" : "/"} />} />
            </Routes>
          </Container>
        </div>
      </Router>
    </UserProvider>
  );
};

const NavigationBar = ({ user, onLogout }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (location.pathname === '/profile') {
      navigate('/tasks');
    } else if (location.pathname === '/completed-tasks' || location.pathname === '/categories') {
      navigate('/profile');
    }
  };

  return (
    <Toolbar style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      {location.pathname === '/login' || location.pathname === '/register' ? (
        <Button color="inherit" component={RouterLink} to="/">Back</Button>
      ) : location.pathname === '/tasks' ? (
        <Button color="inherit" onClick={onLogout}>Logout</Button>
      ) : (location.pathname === '/profile' || location.pathname === '/completed-tasks' || location.pathname === '/categories') ? (
        <Button color="inherit" onClick={handleBackButton}>Back</Button>
      ) : (
        <Box style={{ width: 48 }}></Box>
      )}

      <Typography variant="h6" style={{ flex: 1, textAlign: 'center' }}>Torga</Typography>

      {location.pathname === '/tasks' && (
        <Button color="inherit" component={RouterLink} to="/profile">Profile</Button>
      )}
      {(location.pathname === '/profile' || location.pathname === '/completed-tasks' || location.pathname === '/categories') && (
        <Box style={{ width: 48 }}></Box>
      )}
    </Toolbar>
  );
};

export default App;
