import React, { useEffect, useState, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import { Container, List, ListItem, ListItemText, IconButton, Box, Snackbar, Alert, Button } from '@mui/material';
import { Edit as EditIcon, Add as AddIcon, Done as DoneIcon } from '@mui/icons-material';
import AddTaskForm from './AddTaskForm';
import EditTaskModal from './EditTaskModal';
import CategoryFilter from './CategoryFilter';
import UserContext from '../UserContext';

const TaskList = ({ onLogout }) => {
  const { user } = useContext(UserContext);
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isAddTaskOpen, setIsAddTaskOpen] = useState(false);
  const [notification, setNotification] = useState(false);

  const fetchTasks = async () => {
    try {
      const taskResponse = await axios.get(`${process.env.REACT_APP_API_URL}/tasks`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setTasks(taskResponse.data);
      setFilteredTasks(taskResponse.data);
    } catch (error) {
      console.error('Error fetching tasks:', error.response ? error.response.data : error.message);
    }
  };

  const fetchCategories = async () => {
    try {
      const categoryResponse = await axios.get(`${process.env.REACT_APP_API_URL}/categories`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setCategories(categoryResponse.data);
    } catch (error) {
      console.error('Error fetching categories:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    if (user) {
      fetchTasks();
      fetchCategories();
    }
  }, [user]);

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedFilteredTasks = Array.from(filteredTasks);
    const [reorderedItem] = reorderedFilteredTasks.splice(result.source.index, 1);
    reorderedFilteredTasks.splice(result.destination.index, 0, reorderedItem);
    setFilteredTasks(reorderedFilteredTasks);

    const reorderedTasks = tasks.map(task => reorderedFilteredTasks.find(filteredTask => filteredTask._id === task._id) || task);

    setTasks(reorderedTasks);

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/tasks/reorder`, reorderedTasks.map((task, index) => ({
        ...task,
        priority: index,
      })), {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
    } catch (error) {
      console.error('Failed to reorder tasks', error.response ? error.response.data : error.message);
    }
  };

  const handleTaskAdded = (newTask, newCategory) => {
    setTasks((prevTasks) => [...prevTasks, newTask]);
    setFilteredTasks((prevTasks) => [...prevTasks, newTask]);
    if (newCategory && !categories.find(cat => cat.name === newCategory.name)) {
      setCategories((prevCategories) => [...prevCategories, newCategory]);
    }
    setNotification(true); // Show notification
    setIsAddTaskOpen(false); // Collapse the add task form after adding a task
  };

  const handleTaskUpdated = (updatedTask) => {
    setTasks((prevTasks) => prevTasks.map(task => task._id === updatedTask._id ? updatedTask : task));
    setFilteredTasks((prevTasks) => prevTasks.map(task => task._id === updatedTask._id ? updatedTask : task));
  };

  const handleTaskDeleted = async (taskId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/tasks/${taskId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setTasks((prevTasks) => prevTasks.filter(task => task._id !== taskId));
      setFilteredTasks((prevTasks) => prevTasks.filter(task => task._id !== taskId));
    } catch (error) {
      console.error('Error deleting task:', error.response ? error.response.data : error.message);
    }
  };

  const handleDoubleClick = (task) => {
    setSelectedTask(task);
  };

  const closeModal = () => {
    setSelectedTask(null);
  };

  const getCategoryColor = (categoryName) => {
    const category = categories.find(cat => cat.name === categoryName);
    return category ? category.color : '#ffffff';
  };

  const handleCategoryClick = (categoryName) => {
    if (categoryName === 'All') {
      setSelectedCategories([]);
      setFilteredTasks(tasks);
    } else {
      setSelectedCategories((prevSelected) => {
        const newSelected = prevSelected.includes(categoryName)
          ? prevSelected.filter(category => category !== categoryName)
          : [...prevSelected, categoryName];
        const newFilteredTasks = tasks.filter(task => newSelected.includes(task.category));
        setFilteredTasks(newFilteredTasks);
        return newSelected;
      });
    }
  };

  const markTaskAsDone = async (taskId) => {
    try {
      const updatedTask = await axios.put(`${process.env.REACT_APP_API_URL}/tasks/${taskId}`, { status: 'done' }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setTasks((prevTasks) => prevTasks.filter(task => task._id !== taskId));
      setFilteredTasks((prevTasks) => prevTasks.filter(task => task._id !== taskId));
      // You can optionally update the tasks with the updatedTask response if needed
    } catch (error) {
      console.error('Error marking task as done:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <Container>
      <Box mt={2}>
        <CategoryFilter
          categories={categories}
          selectedCategories={selectedCategories}
          onCategoryClick={handleCategoryClick}
        />
      </Box>
      <List>
        <ListItem
          button
          onClick={() => setIsAddTaskOpen(!isAddTaskOpen)}
          style={{
            backgroundColor: '#e0e0e0',
            margin: '8px 0',
            borderRadius: '4px',
            padding: '16px',
          }}
        >
          <ListItemText primary="Add Task" />
        </ListItem>
        {isAddTaskOpen && (
          <Box mb={2}>
            <AddTaskForm onTaskAdded={handleTaskAdded} />
          </Box>
        )}
      </List>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="tasks">
          {(provided) => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {filteredTasks.map((task, index) => (
                <Draggable key={task._id} draggableId={task._id} index={index}>
                  {(provided, snapshot) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onDoubleClick={() => handleDoubleClick(task)}
                      style={{
                        backgroundColor: getCategoryColor(task.category),
                        boxShadow: snapshot.isDragging ? '0 0 10px rgba(0, 0, 0, 0.2)' : 'none',
                        ...provided.draggableProps.style,
                      }}
                    >
                      <ListItemText primary={task.title} secondary={task.description} />
                      <IconButton edge="end" aria-label="done" onClick={() => markTaskAsDone(task._id)}>
                        <DoneIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="edit" onClick={() => handleDoubleClick(task)}>
                        <EditIcon />
                      </IconButton>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      {selectedTask && (
        <EditTaskModal
          task={selectedTask}
          onClose={closeModal}
          onUpdate={handleTaskUpdated}
          onDelete={handleTaskDeleted}
        />
      )}
      <Snackbar
        open={notification}
        autoHideDuration={3000}
        onClose={() => setNotification(false)}
      >
        <Alert onClose={() => setNotification(false)} severity="success">
          Task added!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TaskList;
