import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Container, List, ListItem, ListItemText, Box, Typography } from '@mui/material';
import UserContext from '../UserContext';
import EditCategoryModal from './EditCategoryModal'; // Import the component

const Categories = () => {
  const { user } = useContext(UserContext);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    if (user) {
      fetchCategories();
    }
  }, [user]);

  const handleEditCategory = (category) => {
    setSelectedCategory(category);
  };

  const handleCloseModal = () => {
    setSelectedCategory(null);
  };

  const handleCategoryUpdated = (updatedCategory) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => (category._id === updatedCategory._id ? updatedCategory : category))
    );
    handleCloseModal();
  };

  const handleCategoryDeleted = (categoryId) => {
    setCategories((prevCategories) => prevCategories.filter((category) => category._id !== categoryId));
    handleCloseModal();
  };

  return (
    <Container>
      <Box mt={2}>
        <Typography variant="h4" component="h1" gutterBottom>
          Categories
        </Typography>
        <List>
          {categories.map((category) => (
            <ListItem
              key={category._id}
              button
              onDoubleClick={() => handleEditCategory(category)}
              style={{ backgroundColor: category.color, margin: '8px 0', borderRadius: '4px', padding: '16px' }}
            >
              <ListItemText primary={category.name} />
            </ListItem>
          ))}
        </List>
        {selectedCategory && (
          <EditCategoryModal
            category={selectedCategory}
            onClose={handleCloseModal}
            onUpdate={handleCategoryUpdated}
            onDelete={handleCategoryDeleted}
          />
        )}
      </Box>
    </Container>
  );
};

export default Categories;
