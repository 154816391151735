import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Correct named import
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Using jwtDecode here
        if (decodedToken.exp * 1000 < Date.now()) {
          // Token has expired
          localStorage.removeItem('token');
          delete axios.defaults.headers.common['Authorization'];
        } else {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          setUser({ ...decodedToken, token });
        }
      } catch (error) {
        console.log('Invalid token');
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
      }
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
