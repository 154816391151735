import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';
import axios from 'axios';
import { Container, Typography, Button, Box } from '@mui/material';

const Profile = () => {
  const { user, setUser } = useContext(UserContext);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/me`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error.response ? error.response.data : error.message);
      }
    };

    if (user) {
      fetchUserData();
    }
  }, [user]);

  const handleDeleteProfile = async () => {
    try {
      await axios.delete(`/users/${user._id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error('Error deleting profile:', error);
    }
  };

  if (!userData) {
    return null;
  }

  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h4">Profile</Typography>
        <Typography variant="h6">Username: {userData.username}</Typography>
        <Typography variant="h6">Email: {userData.email}</Typography>
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={() => navigate('/completed-tasks')}>
            View Completed Tasks
          </Button>
        </Box>
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={() => navigate('/categories')}>
            Manage Categories
          </Button>
        </Box>
        <Box mt={2}>
          <Button variant="contained" color="secondary" onClick={handleDeleteProfile}>
            Delete Profile
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Profile;
