import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel } from '@mui/material';

const EditTaskModal = ({ task, onClose, onUpdate, onDelete }) => {
  const [title, setTitle] = useState(task.title);
  const [description, setDescription] = useState(task.description);
  const [category, setCategory] = useState(task.category);
  const [status, setStatus] = useState(task.status);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error.response ? error.response.data : error.message);
      }
    };
    fetchCategories();
  }, []);

  const handleUpdate = async () => {
    try {
      const updatedTask = { ...task, title, description, category, status };
      await axios.put(`${process.env.REACT_APP_API_URL}/tasks/${task._id}`, updatedTask, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      onUpdate(updatedTask);
    } catch (error) {
      console.error('Error updating task:', error.response ? error.response.data : error.message);
    }
    onClose();
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/tasks/${task._id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      onDelete(task._id);
    } catch (error) {
      console.error('Error deleting task:', error.response ? error.response.data : error.message);
    }
    onClose();
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Edit Task</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            required
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            rows={4}
          />
        </Box>
        <Box mb={2}>
          <TextField
            select
            label="Category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            fullWidth
            SelectProps={{
              native: true,
            }}
          >
            <option value="" />
            {categories.map((cat) => (
              <option key={cat._id} value={cat.name}>
                {cat.name}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mb={2}>
          <FormControlLabel
            control={<Checkbox checked={status === 'done'} onChange={(e) => setStatus(e.target.checked ? 'done' : 'pending')} />}
            label="Mark as Done"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpdate} variant="contained" color="primary">
          Update Task
        </Button>
        <Button onClick={handleDelete} variant="contained" color="secondary">
          Delete Task
        </Button>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTaskModal;
