import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const EditCategoryModal = ({ category, onClose, onUpdate, onDelete }) => {
  const [name, setName] = useState(category.name);
  const [color, setColor] = useState(category.color);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const handleUpdate = async () => {
    try {
      const updatedCategory = { ...category, name, color };
      await axios.put(`${process.env.REACT_APP_API_URL}/categories/${category._id}`, updatedCategory, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      onUpdate(updatedCategory);
    } catch (error) {
      console.error('Error updating category:', error.response ? error.response.data : error.message);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/categories/${category._id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      onDelete(category._id);
    } catch (error) {
      console.error('Error deleting category:', error.response ? error.response.data : error.message);
    }
  };

  const handleRandomizeColor = () => {
    setColor(getRandomColor());
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Edit Category</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            fullWidth
            required
          />
          <Button onClick={handleRandomizeColor} variant="contained" color="primary" style={{ marginTop: '8px' }}>
            Randomize Color
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpdate} variant="contained" color="primary">
          Update Category
        </Button>
        <Button onClick={handleDelete} variant="contained" color="secondary">
          Delete Category
        </Button>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCategoryModal;
