import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Box, Typography, Container } from '@mui/material';

const LandingPage = () => {
  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '20%' }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Welcome to Torga
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please register or login to continue.
      </Typography>
      <Box mt={4}>
        <Link to="/register" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary" style={{ margin: '10px' }}>
            Register
          </Button>
        </Link>
        <Link to="/login" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary" style={{ margin: '10px' }}>
            Login
          </Button>
        </Link>
      </Box>
    </Container>
  );
};

export default LandingPage;
