import React from 'react';
import { Box, Button } from '@mui/material';

const CategoryFilter = ({ categories, selectedCategories, onCategoryClick }) => {
  const handleCategoryClick = (category) => {
    onCategoryClick(category);
  };

  return (
    <Box display="flex" justifyContent="center" mb={2}>
      <Button
        variant="contained"
        style={{ background: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)', margin: '0 5px' }}
        onClick={() => handleCategoryClick('All')}
        className={selectedCategories.length === 0 ? 'selected' : ''}
      >
        All
      </Button>
      {categories.map((category) => (
        <Button
          key={category._id}
          variant="contained"
          style={{ backgroundColor: category.color, margin: '0 5px' }}
          onClick={() => handleCategoryClick(category.name)}
          className={selectedCategories.includes(category.name) ? 'selected' : ''}
        >
          {category.name}
        </Button>
      ))}
    </Box>
  );
};

export default CategoryFilter;
