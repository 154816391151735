import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Container, List, ListItem, ListItemText, IconButton, Box, Typography, Snackbar, Alert } from '@mui/material';
import { Restore as RestoreIcon, Delete as DeleteIcon } from '@mui/icons-material';
import UserContext from '../UserContext';

const CompletedTasks = () => {
  const { user } = useContext(UserContext);
  const [tasks, setTasks] = useState([]);
  const [notification, setNotification] = useState('');

  const fetchCompletedTasks = async () => {
    try {
      const taskResponse = await axios.get(`${process.env.REACT_APP_API_URL}/tasks/completed`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setTasks(taskResponse.data);
    } catch (error) {
      console.error('Error fetching completed tasks:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    if (user) {
      fetchCompletedTasks();
    }
  }, [user]);

  const restoreTask = async (taskId) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/tasks/${taskId}`, { status: 'pending' }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setTasks((prevTasks) => prevTasks.filter(task => task._id !== taskId));
      setNotification('Task restored to pending');
    } catch (error) {
      console.error('Error restoring task:', error.response ? error.response.data : error.message);
    }
  };

  const deleteTask = async (taskId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/tasks/${taskId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setTasks((prevTasks) => prevTasks.filter(task => task._id !== taskId));
      setNotification('Task deleted');
    } catch (error) {
      console.error('Error deleting task:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <Container>
      <Box mt={2}>
        <Typography variant="h4" component="h1" gutterBottom>
          Completed Tasks
        </Typography>
        <List>
          {tasks.map((task) => (
            <ListItem key={task._id} style={{ backgroundColor: '#e0e0e0', margin: '8px 0', borderRadius: '4px', padding: '16px' }}>
              <ListItemText primary={task.title} secondary={task.description} />
              <IconButton edge="end" aria-label="restore" onClick={() => restoreTask(task._id)}>
                <RestoreIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => deleteTask(task._id)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Snackbar
          open={Boolean(notification)}
          autoHideDuration={3000}
          onClose={() => setNotification('')}
        >
          <Alert onClose={() => setNotification('')} severity="success">
            {notification}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default CompletedTasks;
